@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@siemens/component-lib/styles';
@import '/src/assets/styles/icons';
@import '/src/assets/styles/sag';

@font-face {
    font-family: 'Siemens Sans';
    src: url('/assets/fonts/SiemensSans_Prof_Roman.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Siemens Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/SiemensSans_Prof_Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Siemens Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('/assets/fonts/SiemensSans_Prof_Black.woff2') format('woff2');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    /* stylelint-disable */
    font-family: 'Material Icons';
    /* stylelint-enable */
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/MaterialIcons/material-icons.woff2') format('woff2');
}

.material-icons {
    /* stylelint-disable */
    font-family: 'Material Icons';
    /* stylelint-enable */
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/MaterialIcons/material-symbols-outlined.woff2') format('woff2');
}

.material-symbols-outlined,
.material-icons-outlined {
    /* stylelint-disable */
    font-family: 'Material Symbols Outlined';
    /* stylelint-enable */
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

* {
    font-family: 'Siemens Sans', Arial, Helvetica, sans-serif;
}

.strong {
    font-family: 'Siemens Sans Black', Arial, Helvetica, sans-serif;
}

body {
    /* VARIABLES */
    --label-font-size: 14px;
    --input-font-size: 18px;

    margin: 0;
    background-color: var(--bgColorPrimary);
}

html,
body {
    height: 100%;
    font-family: 'Siemens Sans', Arial, Helvetica, sans-serif;
    color: var(--textColor);
}

sag-ui-focus-trap {
  width: 100%;
}