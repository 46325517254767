/* TYPOGRAPHY */

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Siemens Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 28px;
  line-height: 38px;
}

h4 {
  font-size: 26px;
  line-height: 34px;
}

h5 {
  font-size: 22px;
  line-height: 30px;
}

h6 {
  font-size: 20px;
  line-height: 28px;
}

a {
  text-decoration: none;
  color: var(--actionColor);
  cursor: pointer;

  &:hover {
    color: var(--actionHoverColor);
    text-decoration: underline;
  }

  &.disabled {
    color: var(--actionDisabledColor);
    pointer-events: none;
  }
}

/* BUTTONS */

.btn {
  height: 44px;
  min-width: 44px;
  width: fit-content;
  border-radius: 0;
  padding: 12px 32px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: 'Siemens Sans Black', Arial, Helvetica, sans-serif;

  &.disabled,
  &:disabled {
    pointer-events: none !important;
  }
}

.btn-hero {
  background: linear-gradient(90deg, #00ffb9, #00e6dc);
  color: #000028;
  border: 2px solid #000028;
  font-size: 20px;
  height: 60px;

  &:hover {
    background: var(--heroBtnBgHover);
  }
}

.btn-primary {
  background-color: var(--primaryBtnBG);
  color: var(--primaryBtnText);

  &:hover {
    background-color: var(--primaryBtnBGHover);
    color: var(--primaryBtnTextHover);
  }

  &.disabled,
  &:disabled {
    color: var(--actionDisabledColor);
    background-color: var(--actionDisabledBgColor);
  }
}

.btn-secondary {
  background-color: var(--secondaryBtnBG);
  border: 1px solid var(--secondaryBtnText);
  color: var(--secondaryBtnText);

  &:hover {
    border-color: var(--secondaryBtnTextHover);
    background-color: var(--secondaryBtnBGHover);
    color: var(--secondaryBtnTextHover);
  }

  &.disabled,
  &:disabled {
    border-color: var(--actionDisabledColor);
    color: var(--actionDisabledColor);
  }
}

.btn-tetriary {
  background-color: var(--tetriaryBtnBG);
  color: var(--tetriaryBtnText);

  &:hover {
    color: var(--tetriaryBtnTextHover);
    background-color: var(--tetriaryBtnBGHover);
  }

  &.disabled,
  &:disabled {
    color: var(--actionDisabledColor);
  }
}

/* MATERIAL */

/* MENU */
.mat-menu-btn {
  font-family: 'Siemens Sans Black', Arial, Helvetica, sans-serif;

  &:hover {
    text-decoration: none;
  }
}

.mat-mdc-menu-panel {
  min-height: auto !important;
  background: var(--bgColorSecondary) !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-menu-item {
  background: var(--bgColorSecondary);
  font-size: 14px;

  > * {
    color: var(--textColor);
  }

  &:hover:not([disabled]) {
    background: var(--bgHoverColor);
  }
}

/* FORM FIELD */

.mat-mdc-form-field .mdc-notched-outline {
  --mat-mdc-form-field-floating-label-scale: 1;

  z-index: unset;
  box-shadow: 0 2px 4px 0 var(--inputBoxShadow) inset;
  background-color: var(--inputBgColor);
  border: 1px solid var(--inputBorderColor) !important;
  border-radius: 2px;

  > div {
    border: 0 transparent !important;
  }

  &__leading {
    width: 0 !important;
  }
}

/* label */
.mat-mdc-form-field .mdc-notched-outline .mdc-notched-outline__notch {
  width: fit-content !important;

  label {
    --mat-mdc-form-field-label-transform: translate(-4px, -50.75px) scale(1) !important;

    pointer-events: none !important;
    font-size: var(--label-font-size) !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: fit-content;

    mat-label {
      text-overflow: ellipsis;
      overflow: hidden !important;
    }
  }
}

.mat-mdc-form-field:not(.textarea) .mdc-text-field--outlined {
  height: 45px !important;
}

.mat-mdc-form-field .mat-mdc-form-field-text-prefix,
.mat-mdc-form-field .mat-mdc-form-field-text-suffix,
.mat-mdc-form-field .mat-mdc-form-field-infix {
  z-index: 1;

  div,
  span {
    font-size: var(--input-font-size);
    line-height: 28px;
  }
}

/* infix */

/* stylelint-disable */
.mat-mdc-form-field:not(.textarea).mat-mdc-form-field-label-always-float .mdc-text-field--outlined .mat-mdc-form-field-infix {
  /* stylelint-enable */
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  min-height: 45px !important;

  input {
    font-size: var(--input-font-size);
    line-height: 28px;
  }

  input::placeholder,
  .mat-mdc-select-placeholder,
  textarea::placeholder {
    color: var(--inputBorderColor) !important;
  }
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--textColor);
}

/* hint */
.mat-mdc-form-field .mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field .mat-mdc-form-field-hint-wrapper {
  padding-left: 0;
}

/* HOVER STATE */

/* stylelint-disable */
.mat-mdc-form-field:not(.mat-form-field-disabled, .mat-form-field-invalid) .mat-mdc-text-field-wrapper:hover,
/* stylelint-enable */
.mat-mdc-form-field.mat-focused {
  .mdc-notched-outline {
    background-color: var(--inputActiveBgColor) !important;
    border-color: var(--inputActiveBorderColor) !important;

    label {
      color: var(--inputActiveBorderColor) !important;
    }
  }

  .mat-mdc-form-field-infix input::placeholder,
  .mat-mdc-select-placeholder,
  textarea::placeholder {
    color: var(--textColor) !important;
  }
}

/* WARNING STATE */
.mat-mdc-form-field.mat-form-field-invalid {
  .mdc-notched-outline {
    background-color: var(--inputErrorBG) !important;
    border-color: var(--tooltipError) !important;

    label {
      color: var(--warningColor) !important;
    }

    mat-error {
      color: var(--warningColor) !important;
    }
  }

  .mat-mdc-form-field-infix input::placeholder,
  .mat-mdc-select-placeholder,
  textarea::placeholder {
    color: var(--expansionPanelBorder) !important;
  }

  .mat-mdc-select-arrow svg {
    fill: var(--tooltipError);
  }
}

/*  STATE */

.mat-mdc-form-field.mat-form-field-disabled.mat-mdc-form-field-label-always-float .mdc-text-field--outlined {
  /* stylelint-enable */
  .mdc-notched-outline {
    background-color: var(--inputDisabledBg) !important;
    border-color: var(--inputDisabledBorder) !important;
    box-shadow: none;
  }

  .mat-mdc-form-field-infix {
    input::placeholder,
    label,
    .mat-mdc-select-placeholder,
    textarea::placeholder {
      color: var(--actionDisabledColor) !important;
    }
  }

  .mat-mdc-select-arrow svg {
    fill: var(--actionDisabledColor);
  }
}

/* FORM FIELD - FLOATING LABEL */

/* label */
.mat-mdc-form-field:not(.mat-mdc-form-field-label-always-float) {
  .mdc-notched-outline .mdc-notched-outline__notch label {
    /* stylelint-disable */
    --mat-mdc-form-field-label-transform: translate(-4px, -20px) scale(1) !important;
    /* stylelint-enable */

    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px)) !important;
    font-size: var(--input-font-size) !important;
    top: 22px !important;
  }

  &.mat-focused:not(.mat-form-field-hide-placeholder) {
    label {
      font-size: var(--label-font-size) !important;
    }
  }
}

/* infix */
/* stylelint-disable */
.mat-mdc-form-field:not(.mat-mdc-form-field-label-always-float) .mdc-notched-outline .mat-mdc-form-field-infix {
  /* stylelint-enable */
  padding-top: 18px !important;
}

/* select arrow */
/* stylelint-disable */
.mat-mdc-form-field:not(.mat-mdc-form-field-label-always-float) .mat-mdc-select-arrow {
  /* stylelint-enable */
  transform: translateY(-8px);
}

/* MAT-SELECT */

.mat-mdc-select-value,
.mat-mdc-select-arrow {
  color: var(--textColor) !important;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--actionHoverColor);
}

.mat-mdc-select-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 1px solid var(--actionHoverColor);
  padding: 0 !important;
}

.mat-mdc-option {
  background-color: var(--bgColorPrimary) !important;
  color: var(--textColor) !important;
  min-height: 36px !important;
  height: 36px !important;

  > * {
    color: var(--textColor) !important;
  }

  &.mdc-list-item--selected,
  &:hover {
    background-color: var(--bgColorSecondary) !important;
    color: var(--textColor) !important;
  }
}

/* CHECKBOX */

.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 1 !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 1 !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: var(--checkboxSelectedCheck);
  --mdc-checkbox-selected-icon-color: var(--checkboxSelectedBG);
  --mdc-checkbox-selected-hover-icon-color: var(--inputActiveBgColor);
  --mdc-checkbox-selected-focus-icon-color: var(--checkboxSelectedCheck);
  --mdc-checkbox-selected-pressed-icon-color: var(--inputActiveBgColor);
  --mdc-checkbox-unselected-icon-color: var(--radioButtonBorderColor);
  --mdc-checkbox-disabled-unselected-icon-color: var(--actionDisabledColor);
}

.mdc-checkbox__background {
  border-radius: 0 !important;
}

/* stylelint-disable */
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  /* stylelint-enable */
  border-color: var(--checkboxSelectedBG) !important;
  background-color: var(--checkboxSelectedBG) !important;
}

/* stylelint-disable */
.mat-mdc-checkbox
  .mdc-checkbox:hover
  .mdc-checkbox__native-control:enabled:not(:checked, :indeterminate, [data-indeterminate='true'])
  ~ .mdc-checkbox__background {
  /* stylelint-enable */
  background-color: var(--inputActiveBgColor) !important;
  border-color: var(--actionHoverColor) !important;
}

.mat-mdc-checkbox-frame {
  border-color: var(--textColor) !important;
  border-radius: 0 !important;
}

.mat-mdc-checkbox label,
.mat-mdc-radio-label-content {
  color: var(--textColor);
  font-size: 18px;
}

/* RADIO BUTTON */

.mat-mdc-radio-button .mat-radio-ripple {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 0 !important;
}

.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 0 !important;
  transition: none !important;
}

.mat-mdc-radio-button.mat-accent {
  --mat-mdc-radio-checked-ripple-color: transparent;
  --mat-mdc-radio-ripple-color: transparent;
  --mdc-radio-unselected-icon-color: var(--textColor);
  --mdc-radio-unselected-hover-icon-color: var(--actionHoverColor);
  --mdc-radio-unselected-focus-icon-color: var(--actionHoverColor);
  --mdc-radio-disabled-unselected-icon-color: var(--checkboxDisabledBorder);
  --mdc-radio-disabled-selected-icon-color: var(--checkboxDisabledBorder);
  --mdc-radio-selected-focus-icon-color: var(--actionHoverColor);
  --mdc-radio-selected-hover-icon-color: var(--actionHoverColor);
  --mdc-radio-selected-icon-color: var(--checkboxSelectedBG);
  --mdc-radio-selected-pressed-icon-color: var(--checkboxSelectedBG);
}

/* stylelint-disable */
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
  /* stylelint-enable */
  background-color: transparent;
}

/* stylelint-disable */
.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  /* stylelint-enable */
  background-color: var(--inputActiveBgColor);
}

/* stylelint-disable */
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background {
  /* stylelint-enable */
  .mdc-radio__outer-circle {
    background-color: var(--checkboxSelectedBG);
  }

  .mdc-radio__inner-circle {
    background-color: var(--checkboxSelectedCheck) !important;
    border-color: transparent !important;
  }
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--textColor);
  font-size: 18px;
}

.mat-mdc-radio-button .mdc-radio--disabled + label {
  color: var(--checkboxDisabledBorder);
}

/* TOGGLE */

.mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  height: 24px !important;
  border-radius: 20px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  width: 18px !important;
  height: 18px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: 24px !important;
  margin: 0 3px;
}

.mat-mdc-slide-toggle .mdc-switch {
  width: 48px !important;
}

.mat-mdc-slide-toggle .mdc-switch:disabled {
  .mdc-switch__track,
  * {
    opacity: 1 !important;
  }
}

.mat-mdc-slide-toggle,
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-disabled-selected-handle-color: var(--slideThumbDisabledColor);
  --mdc-switch-disabled-unselected-handle-color: var(--slideThumbDisabledColor);
  --mdc-switch-disabled-selected-track-color: var(--actionDisabledBgColor);
  --mdc-switch-disabled-unselected-track-color: var(--actionDisabledBgColor);
  --mdc-switch-unselected-focus-state-layer-color: transparent;
  --mdc-switch-unselected-pressed-state-layer-color: transparented;
  --mdc-switch-unselected-hover-state-layer-color: transparent;
  --mdc-switch-unselected-focus-track-color: var(--slideBarColor);
  --mdc-switch-unselected-hover-track-color: var(--slideBarColor);
  --mdc-switch-unselected-pressed-track-color: var(--slideBarColor);
  --mdc-switch-unselected-track-color: var(--slideBarColor);
  --mdc-switch-unselected-focus-handle-color: var(--slideThumbColor);
  --mdc-switch-unselected-hover-handle-color: var(--slideThumbColor);
  --mdc-switch-unselected-pressed-handle-color: var(--slideThumbColor);
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #ffffff);
  --mdc-switch-unselected-handle-color: var(--slideThumbColor);
  --mdc-switch-selected-focus-state-layer-color: transparent;
  --mdc-switch-selected-handle-color: var(--slideThumbColor);
  --mdc-switch-selected-hover-state-layer-color: transparent;
  --mdc-switch-selected-pressed-state-layer-color: transparent;
  --mdc-switch-selected-focus-handle-color: var(--slideThumbColor);
  --mdc-switch-selected-hover-handle-color: var(--slideThumbColor);
  --mdc-switch-selected-pressed-handle-color: var(--slideThumbColor);
  --mdc-switch-selected-focus-track-color: var(--slideBarActiveColor);
  --mdc-switch-selected-hover-track-color: var(--slideBarActiveColor);
  --mdc-switch-selected-pressed-track-color: var(--slideBarActiveColor);
  --mdc-switch-selected-track-color: var(--slideBarActiveColor);
}

/* TABS */

.mat-mdc-tab-list {
  border-bottom: 2px solid var(--tabBorder);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--tabLabelActiveColor);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: var(--textColor);
  font-size: 20px;
  font-family: 'Siemens Sans Black', Arial, Helvetica, sans-serif;
}

/* TOOLTIP */

.sie-tooltip {
  .mdc-tooltip__surface {
    background-color: var(--tooltipBG) !important;
    font-size: 12px;
    color: var(--bgColorPrimary) !important;
    padding: 8px 16px;
  }
}

/* EXPANSION PANEL */

.version1 {
  .mat-expansion-panel {
    background-color: var(--bgColorSecondary);
    color: var(--textColor);

    .mat-expansion-indicator::after {
      color: var(--actionColor) !important;
    }

    &.mat-expanded .mat-expansion-indicator::after {
      color: var(--actionHoverColor) !important;
    }

    &:hover {
      background-color: var(--bgHoverColor);
    }
  }

  .mat-expansion-panel-header-title {
    color: var(--textColor);
    font-size: 18px;
  }

  .mat-expansion-panel-content {
    font-family: 'Siemens Sans', Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
  }
}

.version2 {
  .mat-expansion-indicator::after {
    color: var(--actionColor) !important;
  }

  .mat-expansion-panel {
    background-color: transparent;
    color: var(--textColor);
    border-top: 1px solid var(--expansionPanelBorder);
    border-bottom: 1px solid var(--expansionPanelBorder);
    border-radius: 0 !important;
    box-shadow: none;

    .icon.expanded {
      font-size: 24px;
      display: none;
    }

    .icon.minimized {
      display: block;
      font-size: 24px;
    }

    &.mat-expanded .mat-expansion-indicator::after {
      color: var(--actionHoverColor) !important;
    }

    &.mat-expanded .icon.expanded {
      display: block;
    }

    &.mat-expanded .icon.minimized {
      display: none;
    }

    &:hover {
      background-color: var(--expansionPanelHoverBG);
      border-color: var(--expansionPanelHoverBorder);
    }
  }

  .mat-expansion-panel-header {
    height: 96px;
  }

  .mat-expansion-panel-header-title {
    color: var(--textColor);
    font-size: 18px;
  }

  .mat-expansion-panel-content {
    font-family: 'Siemens Sans', Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--bgColorSecondary) !important;
  height: 80px;
  min-width: 500px !important;
}

input[type="radio"]:focus-visible {
  outline: 2px solid var(--focusOutline); 
  outline-offset: 2px; 
  border-radius: 50%; 
}
